/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import RoiOverlay from './RoiOverlay'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import { WarningIcon } from '../../../components/svgs/Svgs'
import Button from '../../../components/common/Button'
import { COLORS } from '../../../shared/utils/colors'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { useLoad } from '../../../shared/hooks/requests'
import { BAZAAR_ROI_BY_SCAMERA, BAZAAR_SCAMERA_STREAM } from '../../../shared/utils/urls'

// Тестовые ROI (до тех пор, пока не подтягиваете реальные)
// import rois from '../path/to/testRois.json' // Или прямо объект

function SmartCameraStream() {
    const { id } = useParams()
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()

    const width = new URLSearchParams(window.location.search).get('width')

    // Получаем URL потока
    const getStreamUrl = useLoad(
        { url: BAZAAR_SCAMERA_STREAM.replace('{id}', id), params: { enable: true } },
        [],
    )
    const streamUrl = getStreamUrl.response ? getStreamUrl.response.streamCameraUrl : ''

    const [videoError, setVideoError] = useState('')
    const [loading, setLoading] = useState(true)

    // Здесь будет храниться размер плеера (динамически?)
    const [playerSize, setPlayerSize] = useState({ width: 0, height: 0 })

    // Ссылка на контейнер или сам ReactPlayer, чтобы узнавать фактический размер
    const playerRef = useRef(null)

    const getRois = useLoad(
        { url: BAZAAR_ROI_BY_SCAMERA.replace('{id}', id) },
        [],
    )
    const rois = getRois.response || []

    const sortedRois = rois.map(roi => ({
        ...roi,
        points: roi.points.sort((a, b) => a.orderNumber - b.orderNumber)
    }));


    const handleVideoError = (e) => {
        console.log(e)
        setVideoError('Internet tarmogidagi muamolar sababli, video uzatish sharoyiti mavjud emas')
        setLoading(false)
    }

    const handleVideoReady = () => {
        setLoading(false)
        // Можно вычислить размер контейнера:
        if (playerRef.current) {
            // Например, если playerRef указывает на div-обёртку
            const rect = playerRef.current.getBoundingClientRect()
            setPlayerSize({ width: rect.width, height: rect.height })
        }
    }

    const handleReconnect = async () => {
        if (getStreamUrl.loading) return
        // eslint-disable-next-line no-unused-vars
        const { success, error } = await getStreamUrl.request({ params: { enable: true } })
        if (success) {
            setVideoError('')
            showMessage('Reconnected successfully', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    useEffect(() => {
        if (getStreamUrl.error === undefined) {
            handleVideoError()
        }
    }, [getStreamUrl.error])

    return (
        <div
            className={cn(css(s.body), 'flex bg-white')}
            style={{ width: width ? `${width}px` : '100%' }}
        >
            <div
                ref={playerRef}
                className="flex-1 flex-cent justify-center rounded-m overflow-hidden relative"
                style={{ position: 'relative' }}
            >
                {!getStreamUrl.loading && (
                    <ReactPlayer
                        url={streamUrl}
                        playing
                        muted
                        onReady={handleVideoReady}
                        onError={handleVideoError}
                        width="100%"
                        height="100%"
                    />
                )}

                {/* Overlay ROI */}
                {/* Допустим, мы вычислили playerSize. Если == 0,
                    можем просто width="100%", height="100%" */}
                <RoiOverlay
                    rois={sortedRois}
                    width={playerSize.width}
                    height={playerSize.height}
                />

                {loading && (
                    <span
                        className="loader"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'black',
                        }}
                    >
                        <LoadingSpinner strokeWidth={5} width={48} />
                    </span>
                )}

                {videoError && (
                    <div className={cn(css(s.bgContainer), 'flex-cent')}>
                        <div className={css(s.bg)} />
                        <div className={cn(css(s.errorMessage), 'flex-col items-center justify-center gap-2_5')}>
                            <div className="flex-col items-center gap-1_5">
                                <WarningIcon />
                                <h3 className="text-m font-500">Ogohlantirish</h3>
                            </div>
                            <p className="text-s font-400">{videoError}</p>
                            <Button className={css(s.reconBtn)} onClick={handleReconnect}>
                                Qayta ulaning
                                {' '}
                                {getStreamUrl.loading ? <LoadingSpinner width={14} strokeWidth={4} /> : null}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    body: {
        aspectRatio: '16/9',
        boxSizing: 'border-box',
    },
    bgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.40)',
        width: '100%',
        height: '100%',
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(5px)',
        zIndex: 1,
    },
    errorMessage: {
        borderRadius: 10,
        padding: '20px 24px',
        backgroundColor: 'rgba(255, 255, 255, 0.30)',
        backdropFilter: 'blur(10px)',
        border: '1px solid #C3C3C3',
        width: '20rem',
        position: 'relative',
        zIndex: 2,
        color: COLORS.white,
        textAlign: 'center',
        marginTop: '10px',
    },
    reconBtn: {
        padding: '5px 10px',
        borderRadius: 4,
        background: COLORS.white,
        marginTop: 22,
        fontSize: 10,
        fontWeight: '400',
        color: COLORS.black,
    },
})

export default SmartCameraStream
