/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import Checkbox from '@mui/material/Checkbox'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'
import { GET_PHOTOS, POST_PHOTOS } from '../../../shared/utils/urls'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import { useMessage } from '../../../shared/hooks/message'

function ImgCard({ id, isChecked, onCheckboxChange, url }) {
    return (
        <div className={`w-[100%] relative h-[175px] p-[3px] ${isChecked ? 'border-[#FE6258] '
            + 'border-solid border-[3px]' : ''} overflow-hidden rounded-[12px]`}
        >
            <div className="w-[100%] h-[100%] rounded-[7px] overflow-hidden">
                <img src={url} alt="img of mine" className="w-[100%] h-[100%] object-cover" />
            </div>
            <div className="absolute top-0 right-0">
                <Checkbox
                    checked={isChecked}
                    onChange={() => onCheckboxChange(id)}
                    sx={{
                        color: '#FE6258',
                        '&.Mui-checked': { color: '#FE6258' },
                    }}
                />
            </div>
        </div>
    )
}

export function LabelingModal({ id, modalIds }) {
    const showMessage = useMessage()
    const [open, setOpen] = useState(false)
    const [checkedItems, setCheckedItems] = useState([])
    const [currentIdIndex, setCurrentIdIndex] = useState(null) // Start with null

    // Set the initial currentIdIndex when the modal opens
    useEffect(() => {
        if (id) {
            setCurrentIdIndex(id)
        }
    }, [id])

    useEffect(() => {
        if (open && currentIdIndex) {
            getImages.request()
        }
    }, [currentIdIndex, open])

    const currentUser = modalIds.find((item) => item.id === currentIdIndex) || {}
    const { firstName = '', lastName = '', name = '' } = currentUser

    const getImages = useGetRequest({ url: `${GET_PHOTOS}${currentIdIndex}/photos` })
    const postImages = usePostRequest({ url: POST_PHOTOS }, [])
    const images = getImages.response || []

    useEffect(() => {
        if (images.length > 0) {
            setCheckedItems(images.map((img) => ({ id: img.id, url: img.url, checked: true })))
        }
    }, [images])

    const handleOpen = () => {
        setCurrentIdIndex(id) // Ensure correct ID when opening
        setOpen(true)
    }

    const handleClose = () => {
        setCheckedItems([])
        setOpen(false)
    }

    const handleNext = () => {
        const currentIndex = modalIds.findIndex((item) => item.id === currentIdIndex)
        if (currentIndex + 1 === modalIds.length) return
        setCheckedItems([]) // Reset checked items before navigating

        // Check if we're not at the last valid index
        if (currentIndex < modalIds.length - 1) {
            const nextIndex = currentIndex + 1
            setCurrentIdIndex(modalIds[nextIndex].id) // Update to next item
        }
    }

    const handlePrevious = () => {
        const currentIndex = modalIds.findIndex((item) => item.id === currentIdIndex)
        if (currentIndex === 0) return // Prevent going below the first item

        setCheckedItems([]) // Reset checked items before navigating

        // Check if we're not at the first valid index
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1
            setCurrentIdIndex(modalIds[prevIndex].id) // Update to previous item
        }
    }

    const handleCheckboxChange = (imgId) => {
        setCheckedItems((prev) => prev.map((item) => (item.id === imgId
            ? { ...item, checked: !item.checked } : item)))
    }

    const handleCancel = () => {
        setCheckedItems(images.map((img) => ({ id: img.id, url: img.url, checked: true }))) // Reset to checked state
    }

    const handleSave = async () => {
        const selectedImages = checkedItems
            .filter((item) => item.checked)
            .map((item) => item.id) // Extract only the `id` values

        const { success } = await postImages.request({
            params: { identity_id: currentIdIndex },
            data: selectedImages, // Send the IDs as an array of integers
        })

        if (success) {
            showMessage('Photos labeled', 'success-msg')
        }
    }

    const showCancelButton = checkedItems.some((item) => !item.checked)

    return (
        <>
            <button
                className="px-[20px] py-[10px] text-white font-[400]
            bg-main-300 rounded-[10px]"
                onClick={handleOpen}
            >
                Details
            </button>

            <Modal open={open} onClose={handleClose} className="flex items-center justify-center">
                <div className="w-[60%] rounded-[10px] overflow-hidden flex flex-col max-h-[700px] bg-white">
                    <div className="w-full flex items-center justify-between px-[20px] py-[15px] bg-main-300">
                        <div className="flex items-center gap-[20px]">
                            <span className="text-white font-[400]">{name}</span>
                            <span className="text-white font-[400]">{firstName}</span>
                            <span className="text-white font-[400]">{lastName}</span>
                        </div>
                        <div onClick={handleClose} className="text-white cursor-pointer">
                            <CancelIcon />
                        </div>
                    </div>

                    <div className="w-full flex-grow pt-[10px] px-[20px] overflow-y-auto">
                        {getImages.loading ? (
                            <LoadingSpinner />
                        ) : (
                            <div className="grid grid-cols-4 gap-x-[20px] gap-y-[20px]">
                                {checkedItems.map((item) => (
                                    <ImgCard
                                        key={item.id}
                                        id={item.id}
                                        url={item.url}
                                        isChecked={item.checked}
                                        onCheckboxChange={handleCheckboxChange}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="w-full py-[15px] flex items-center justify-between px-[20px] bg-white">
                        <div className="flex items-center gap-[20px]">
                            <div
                                onClick={handlePrevious}
                                className="py-[10px] px-[10px]
                            hover:bg-gray-100/50 transition-all duration-500 ease-linear rounded-[50px] cursor-pointer"
                            >
                                <ArrowBack />
                            </div>
                            <div
                                onClick={handleNext}
                                className="py-[10px] px-[10px]
                            hover:bg-gray-100/50 transition-all duration-500 ease-linear rounded-[50px] cursor-pointer"
                            >
                                <ArrowForward />
                            </div>
                        </div>
                        <div className="flex items-center gap-[20px]">
                            {showCancelButton && (
                                <>
                                    <button
                                        onClick={handleCancel}
                                        className="px-[20px] py-[10px] bg-[#E1E1E166] text-[#5A5A5A] rounded-[10px]"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={handleSave}
                                        className="px-[20px] py-[10px] bg-[#4393C7] rounded-[10px] text-white"
                                    >
                                        Saqlash
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
