import React, { createContext, useContext, useState } from 'react'
import moment from 'moment'
import { usePersistState } from '../../shared/hooks/state'

const FilterContext = createContext(undefined)

// eslint-disable-next-line react/prop-types
export default function FilterContextProvider({ children }) {
    const [cameraFilter, setCameraFilter] = usePersistState('cameraFilter', { bId: '', rId: '' })
    const [workspaceFilter, setWorkspaceFilter] = usePersistState('workspaceFilter', {
        jetsonDeviceId: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })
    const [dateFilter, setDateFilter] = useState('dateFilter', {
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })

    const [streamAnalyticsFilter, setStreamAnalyticsFilter] = usePersistState('streamAnalyticsFilter', {
        jetsonDeviceId: '',
    })
    const [queueAnalyticsFilter, setQueueAnalyticsFilter] = usePersistState('queueAnalyticsFilter', {
        bId: '',
        rId: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })
    const [safezoneAlarmFilter, setSafezoneAlarmFilter] = usePersistState('safezoneAlarmFilter', {
        bId: '',
        rId: '',
    })
    const [speechAnalyticsFilter, setSpeechAnalyticsFilter] = usePersistState('safezoneAlarmFilter', {
        bId: '',
        rId: '',
    })
    const [entityFilters, setEntityFilters] = useState({
        dashboard: 'all',
        master: '',
        client: '',
        user: '',
    })
    const [filterState, setFilterState] = useState({
        api: '',
        authClient: '',
        authenticated: '',
        face_matched: '',
        is_spoofed: '',
        search: '',
    })
    const [jetsonFilter, setJetsonFilter] = usePersistState('jetsonFilter', { jetsonDeviceId: '' })
    const [branchEntityFilter, setBranchEntityFilter] = useState({
        filter1: 'all',
        filter2: 'all',
        jetsonFilter: '',
        notificationFilter: 'ALL',
        restart: '',
    })
    const [logsFilter, setLogsFilter] = usePersistState('logsFilter', {
        jetsonDeviceId: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })
    const [deploymentCameraFilter, setDeploymentCameraFilter] = usePersistState('deploymentCameraFilter', {
        status: '',
        jetsonDeviceId: '',
    })

    const [identityFilter, setIdentityFilter] = useState({
        rId: '',
        dId: '',
        search: '',
        tenantEntityId: '',
    })

    const [attendaceFilter, setAttendanceFilter] = useState({
        eventType: '',
        mobile: '',
        consumer_from: '',
        consumer_to: '',
        search: '',
        region: '',
        district: '',
        entity: '',
    })

    const [attendFilter, setAttendFilter] = useState({
        region: '',
        district: '',
        date: moment().format('YYYY-MM-DD'),
        sort_type: '',
        sort_role: '',
        sort_quantity: '',
        ratio_from: '',
        ratio_to: '',
        search: '',
    })

    const [reportFilter, setReportFilter] = useState({
        region: '',
        district: '',
        entity_id: '',
    })

    const [systemFilter, setSystemFilter] = useState({
        region: '',
        district: '',
        date: moment().format('YYYY-MM-DD'),
    })

    const [issueFilter, setIssueFilter] = useState({
        search: '',
        status_code: '',
        path: '',
        method: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })

    const [similarityFilter, setSimilarityFilter] = usePersistState('similarityFilter', {
        region: '',
        district: '',
        entity_id: '',
    })

    const [managmentFilter, setManagmentFilter] = useState({
        region: '',
        district: '',
        search: '',
    })

    const [mapFilter, setMapFilter] = useState({
        region: '',
        district: '',
    })

    const [guestFilter, setGuestFilter] = useState({
        status: '',
    })

    const [labelFilter, setLabelFilter] = useState({
        region: '',
        district: '',
        entity: '',
        startPeriod: moment().format('YYYY-MM-DD'),
        endPeriod: moment().format('YYYY-MM-DD'),
    })

    const setEntityFilter = (filterName, value) => {
        setEntityFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }))
    }

    const setManagmentFilters = (updatedFilters) => {
        setManagmentFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setGuestFilters = (updatedFilters) => {
        setGuestFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setReportFilters = (updatedFilters) => {
        setReportFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setSystemFilters = (updatedFilters) => {
        setSystemFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setIssueFilters = (updatedFilters) => {
        setIssueFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setAttendanceFilters = (updatedFilters) => {
        setAttendanceFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setAttendFilters = (updatedFilters) => {
        setAttendFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setIdentityFilters = (updatedFilters) => {
        setIdentityFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }
    const setLabelFilters = (updatedFilters) => {
        setLabelFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setBranchEntityFilters = (filterName, value) => {
        setBranchEntityFilter((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }))
    }

    const setAuthFilters = (updatedFilters) => {
        setFilterState((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    const setMapFilters = (updatedFilters) => {
        setMapFilter((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }))
    }

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <FilterContext.Provider value={{
            cameraFilter,
            setCameraFilter,
            workspaceFilter,
            setWorkspaceFilter,
            queueAnalyticsFilter,
            setQueueAnalyticsFilter,
            safezoneAlarmFilter,
            setSafezoneAlarmFilter,
            speechAnalyticsFilter,
            setSpeechAnalyticsFilter,
            streamAnalyticsFilter,
            setStreamAnalyticsFilter,
            entityFilters,
            jetsonFilter,
            setJetsonFilter,
            logsFilter,
            setLogsFilter,
            filterState,
            setAuthFilters,
            setEntityFilter,
            branchEntityFilter,
            setBranchEntityFilters,
            managmentFilter,
            setManagmentFilters,
            deploymentCameraFilter,
            setDeploymentCameraFilter,
            attendaceFilter,
            setAttendanceFilters,
            identityFilter,
            setIdentityFilters,
            dateFilter,
            setDateFilter,
            mapFilter,
            setMapFilters,
            attendFilter,
            setAttendFilters,
            reportFilter,
            setReportFilters,
            guestFilter,
            setGuestFilters,
            systemFilter,
            setSystemFilters,
            issueFilter,
            setIssueFilters,
            similarityFilter,
            setSimilarityFilter,
            labelFilter,
            setLabelFilters,
        }}
        >
            {children}
        </FilterContext.Provider>
    )
}

export const useFilter = () => useContext(FilterContext)
