/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import SearchInput from '../../../shared/ui/SearchInput'
import Table from '../../../shared/ui/Table'
import { SimilarityFilter } from './SimilarityFilter'
import { useGetRequest } from '../../../shared/hooks/requests'
import {  GET_SIMILARITY_AREA, GET_SIMILARITY_ENTITY } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { SimilarityTableItems } from './SimilarityTableItems'

const cols = [
    { id: 1, title: 'ID', width: '16%' },
    { id: 2, title: 'Entity Name', width: '16%' },
    { id: 2, title: 'FirstName', width: '16%' },
    { id: 3, title: 'LastName', width: '16%' },
    { id: 4, title: 'Photo', width: '16%' },
    { id: 5, title: 'PINFL', width: '16%' },
    { id: 8, title: 'Details', align: 'center', width: '16%' },
]

export function SimilarityTable() {
    const [activeBtn, setActiveBtn] = useState('Area')
    const { similarityFilter } = useFilter()
    const { dateFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const handleSearch = () => {

    }
    const activeUrl = activeBtn === 'Area' ? GET_SIMILARITY_AREA : GET_SIMILARITY_ENTITY
    const getSimilarity = useGetRequest()
    const data = getSimilarity.response ? getSimilarity.response.items : []
    const total = getSimilarity.response ? getSimilarity.response.total : 0

    useEffect(() => {
        setPage(1)
    }, [activeBtn, similarityFilter, dateFilter])

    useEffect(() => {
        if (dateFilter.startPeriod && dateFilter.endPeriod) {
            getSimilarity.setResponse([]); // Clear data
            getSimilarity.setLoading(true);
            getSimilarity.request({
                url: activeUrl,
                params: {
                    ...(similarityFilter.region !== '' ? { region_id: similarityFilter.region } : {}),
                    ...(similarityFilter.district !== '' && similarityFilter.district !== ''
                        ? { district_id: similarityFilter.district } : {}),
                    ...(similarityFilter.district !== '' && similarityFilter.entity_id !== ''
                        ? { tenant_entity_id: similarityFilter.entity_id } : {}),
                    ...(similarityFilter.from !== '' ? { min_distance: similarityFilter.from } : {}),
                    ...(similarityFilter.to !== '' ? { max_distance: similarityFilter.to } : {}),
                    start_date: dateFilter.startPeriod,
                    end_date: dateFilter.endPeriod,
                    page,
                    pageSize,
                },
            })
        }
    }, [similarityFilter.region, similarityFilter.district,
        similarityFilter.entity_id, similarityFilter.to, dateFilter, page, pageSize, activeBtn])
    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <SimilarityFilter />
            </div>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <div className="flex items-center gap-[15px]">
                        <Typography className="flex items-center gap-1_5 text-m font-600">
                            Similarity
                        </Typography>
                        <div className="flex items-center gap-[10px]">
                            <button
                                onClick={() => setActiveBtn('Entity')}
                                className={`py-[10px] px-[15px]
                            text-black ${activeBtn === 'Entity' ? 'bg-gray-100' : 'bg-gray-50'} rounded-[10px]`}
                            >
                                Entity
                            </button>
                            <button
                                onClick={() => setActiveBtn('Area')}
                                className={`py-[10px] px-[15px]
                            text-black ${activeBtn === 'Area' ? 'bg-gray-100' : 'bg-gray-50'} rounded-[10px]`}
                            >
                                Area
                            </button>
                        </div>
                    </div>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={getSimilarity.loading}
                    totalCount={total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <SimilarityTableItems
                            item={item}
                            key={item.id}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
