import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { useDispatch, useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'
import * as Yup from 'yup'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MenuItem, Select } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { redo, undo, updatePolygonColors } from '../../../shared/store/polygonSlice'
import { RedoIcon, UndoIcon } from '../../../components/svgs/Svgs'
import { ColorPaletteIcon } from '../../../components/svgs/SvgPictures'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const formInitialValues = {
    label: 'Polygon 1',
    labels: [],
    description: '',
    type: '',
    userId: null,
    peopleCountThreshold: null,
    safeZoneStartTime: null,
    safeZoneEndTime: null,
    spotId: null,
}

const VALIDATION_SCHEMA = Yup.object().shape({
    description: Yup.string().optional(),
})

export default function RoiForm({ onSubmit, loading }) {
    const dispatch = useDispatch()
    const polygons = useSelector((state) => state.polygon.polygons)
    const activePolygonIndex = useSelector((state) => state.polygon.activePolygonIndex)
    const shouldCloseForm = useSelector((state) => state.polygon.shouldCloseForm)

    const [color, setColor] = useState('#0B9E34')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [isFormVisible, setIsFormVisible] = useState(true)

    const handleColorChange = (c) => {
        setColor(c.hex)
        // Обновляем цвет текущего полигона в store
        if (polygons[activePolygonIndex]) {
            dispatch(updatePolygonColors({ id: polygons[activePolygonIndex].id, color: c.hex }))
        }
    }

    useEffect(() => {
        if (shouldCloseForm) {
            setIsFormVisible(false)
        }
    }, [shouldCloseForm])

    useEffect(() => {
        // Здесь пример использования polygons, если нужно
        if (polygons.length > 0) {
            transformArray(polygons[0].flattenedPoints)
        }
    }, [polygons])

    useEffect(() => {
        console.log(polygons)
    }, [polygons])

    // Пример вспомогательной функции
    const transformArray = (array) => {
        if (!array) return
        const resultArray = []
        for (let i = array.length - 2; i >= 0; i -= 2) {
            resultArray.push(array[i], array[i + 1])
        }
        // eslint-disable-next-line consistent-return
        return resultArray
    }

    // Если форма скрыта — не рендерим
    if (!isFormVisible) return null

    return (
        <Formik
            enableReinitialize
            initialValues={{ ...formInitialValues, ...polygons[activePolygonIndex] }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {/* eslint-disable-next-line no-unused-vars */}
            {({ values, setFieldValue, handleChange }) => (
                <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                    <div className="flex-col gap-2_5">
                        <Form className={cn(css(s.formBody), 'flex-col items-end gap-7_5')}>
                            <div className="w-full flex-col gap-2_5">
                                <FormTextField
                                    required
                                    label="Name"
                                    name="label"
                                    handleChange={handleChange}
                                />

                                <FormTextField
                                    // required
                                    label="Shop id"
                                    name="shopId"
                                    handleChange={handleChange}
                                />
                                <Select
                                    value={values.spotId || 1}
                                    initialValue={values.spotId}
                                    onChange={handleChange}
                                    name="spotId"
                                    label="spot"
                                    displayEmpty
                                    className="w-full border-b border-gray-400 outline-none text-gray-500"
                                    sx={{
                                        '&.MuiOutlinedInput-root': {
                                            '& fieldset': { border: 'none' },
                                        },
                                        '& .MuiSelect-select': {
                                            paddingLeft: 0,
                                        },
                                        '&.MuiInputBase-root': {
                                            fontSize: '16px',
                                            color: '#757575', // Default placeholder color
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: '#757575', // Dropdown arrow color
                                        },
                                        '& .MuiSelect-select.Mui-selected': {
                                            color: '#000', // Change selected text color to black
                                        },
                                        '& .MuiMenuItem-root.Mui-selected': {
                                            color: '#000', // Ensures selected menu item is also black
                                        },
                                    }}
                                >
                                    <MenuItem value="" disabled>Select Type</MenuItem>
                                    <MenuItem value={1}>Legal</MenuItem>
                                    <MenuItem value={2}>Illegal</MenuItem>
                                </Select>
                            </div>

                            {/* Блок с кнопками: Save и Close */}
                            <div className="flex w-full justify-between items-center">
                                <PrimaryBtn
                                    title="Close"
                                    // type="button", чтобы не отправлять форму
                                    type="button"
                                    onClick={() => setIsFormVisible(false)}
                                />
                                <PrimaryBtn
                                    loading={loading}
                                    title="Save"
                                    type="submit"
                                />
                            </div>
                        </Form>

                        <ul className={cn(css(s.toolsCont), 'flex items-center relative')}>
                            <li
                                className="flex-col items-center gap-2"
                                onClick={() => dispatch(undo())}
                            >
                                <UndoIcon />
                                Undo
                            </li>

                            <li
                                className="flex-col items-center gap-2"
                                onClick={() => dispatch(redo())}
                            >
                                <RedoIcon />
                                Redo
                            </li>

                            <li
                                className="flex-col items-center gap-2 relative"
                                onClick={() => setShowColorPicker(!showColorPicker)}
                            >
                                <ColorPaletteIcon />
                                <h3>Color palette</h3>

                                {showColorPicker && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: -300,
                                            left: 150,
                                            zIndex: 1000,
                                        }}
                                    >
                                        <SketchPicker
                                            color={color}
                                            onChange={handleColorChange}
                                            onClose={() => setShowColorPicker(false)}
                                        />
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </LocalizationProvider>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    btn: {
        height: 38,
        backgroundColor: COLORS.disabled,
        padding: '0 25px',
        color: COLORS.white,
    },
    submitBtn: {
        backgroundColor: COLORS.mainBlueBg,
        ':hover': {
            backgroundColor: COLORS.mainBlue,
        },
    },
    formBody: {
        position: 'relative',
        padding: '22px 16px',
        borderRadius: 10,
        background: COLORS.white,
        ':after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '-15px',
            transform: 'translateY(-50%)',
            borderWidth: '20px 20px 20px 0',
            borderStyle: 'solid',
            borderColor: `transparent ${COLORS.white} transparent transparent`,
        },
    },
    toolsCont: {
        padding: '10px 0',
        borderRadius: 10,
        border: '1px solid #F8F8F8',
        background: COLORS.white,
        ':nth-child(1n) > *': {
            borderRight: '1px solid #F8F8F8',
            listStyleType: 'none',
            padding: '0 20px',
            height: 'inherit',
            cursor: 'pointer',
            userSelect: 'none',
        },
        ':nth-child(1n) > :last-child': {
            borderRight: 'none',
        },
        position: 'relative',
    },
})
