/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MenuItem, Paper, Select } from '@mui/material'
import moment from 'moment/moment'
import Skeleton from 'react-loading-skeleton'
import { debounce } from 'lodash'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ALLOWED_REGIONS,
    DISTRICTS_LIST,
    TENANT_ENTITY_CUSTOMER_FILTER,
    TENANT_ENTITY_FILTER } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'

export function SimilarityFilter() {
    const { similarityFilter, setSimilarityFilter } = useFilter()
    const {dateFilter, setDateFilter} = useFilter()
    const [fromValue, setFromValue] = useState(similarityFilter.from);
    const [toValue, setToValue] = useState(similarityFilter.to);
    const userType = localStorage.getItem('userType')
    const entityListUrl = userType === 'tenant_admin' ? TENANT_ENTITY_FILTER : TENANT_ENTITY_CUSTOMER_FILTER
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: similarityFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = [
        { value: '', label: '-' },
        ...regions.map((region) => ({ value: region.id, label: region.name })),
    ]
    const districtOptions = districts.length ? [
        { value: '', label: '-' }, // Default option
        ...districts.map((district) => ({ value: district.id, label: district.name })),
    ] : []

    const [searchTerm, setSearchTerm] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const getAllEntities = useGetRequest({ url: entityListUrl,
        params: { search: searchTerm,
            regionId: similarityFilter.region,
            districtId: similarityFilter.district } }, [])
    const data = getAllEntities.response ? getAllEntities.response : []
    const options = data ? data.map((e) => ({ value: e.id,
        label: e.name,
        col1: e.name,
        col2: e.region.name,
        col3: e.district.name })) : []

    const handleSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 300),
        [], // only create the debounce function once
    )

    const onSearchChange = (value) => {
        setSearchValue(value)
        handleSearch(value)
    }

    useEffect(() => {
        if (searchTerm !== null || similarityFilter.district) {
            getAllEntities.request()
        }
    }, [searchTerm, similarityFilter.district])

    useEffect(() => {
        if(similarityFilter.region !== '') {
            getDistricts.request()
        }
    }, [similarityFilter.region])


    useEffect(() => {
        const today = moment().format('YYYY-MM-DD');
        const oneWeekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');

        setDateFilter({
            startPeriod: oneWeekAgo,
            endPeriod: today,
        });
    }, []);

    const handleSubmit = () => {
        const newFilters = {
            ...similarityFilter, // Keep existing filters
            from: fromValue?.trim(),
            to: toValue?.trim(),
        };

        setSimilarityFilter((prevFilters) =>
            JSON.stringify(prevFilters) !== JSON.stringify(newFilters) ? newFilters : prevFilters
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? regionOptions.find(
                            (v) => v.value === value,
                        )?.label : 'Choose region')}
                        sx={{ height: 37, width: '10rem' }}
                        value={similarityFilter.region}
                        onChange={(e) => setSimilarityFilter((prev) => ({
                            ...prev, region: e.target.value, district: '', entity_id: ''
                        }))}
                    >
                        {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        )) : loadRegions.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? districtOptions.find(
                            (v) => v.value === value,
                        )?.label : 'Choose district')}
                        sx={{ height: 37, width: '10rem' }}
                        value={similarityFilter.district}
                        disabled={!districtOptions.length}
                        onChange={(e) => setSimilarityFilter((prev) => ({
                            ...prev, district: e.target.value, entity_id: ''
                        }))}
                    >
                        {!getDistricts.loading && districtOptions.length ? districtOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        )) : getDistricts.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>
                    <SelectInput
                        styles={{
                            minWidth: '12rem',
                            '& .MuiInputBase-root': {
                                minHeight: '30px', // Set minimum height for container
                                padding: '4px 8px', // Adjust padding for a compact look
                            },
                            '& .MuiSelect-select': {
                                padding: '4px 8px', // Adjust padding inside the select to reduce height
                                display: 'flex',
                                alignItems: 'center', // Vertically center text
                                minHeight: '20px', // Set the desired minimum height here
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                minHeight: '30px', // Match the outline to the container height
                            },
                            '& .MuiInputLabel-root': {
                                top: '-7px', // Move label slightly upward
                            },
                        }}
                        variant="outlined"
                        name="tenantEntityId"
                        label="Choose entity"
                        selectPlaceholder="Choose entity"
                        onChange={(e) => setSimilarityFilter((prev) => ({
                            ...prev, entity_id: e.target.value
                        }))}
                        value={similarityFilter.entity_id}
                        menuItems={similarityFilter.district ? options : []}
                        loading={getAllEntities.loading}
                        searchable
                        placeholder="Search entities..."
                        onSearch={onSearchChange}
                        searchValue={searchValue}
                        tableMode
                    />

                    <div className="flex gap-2">
                        {/* Start Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="Start Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null}
                                onChange={(e) => {
                                    const formattedStartDate = e ? moment(e)
                                        .format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        startPeriod: formattedStartDate,
                                        endPeriod: prev.endPeriod && moment(formattedStartDate)
                                            .isBefore(moment(prev.endPeriod)) ? prev.endPeriod : formattedStartDate,
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                            />
                        </div>

                        {/* End Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="End Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.endPeriod ? moment(dateFilter.endPeriod) : null}
                                onChange={(e) => {
                                    const formattedEndDate = e ? moment(e)
                                        .format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        endPeriod: formattedEndDate,
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                                minDate={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null}
                            />
                        </div>

                        <div className="w-[100px] border-gray-200/50 rounded-[10px] overflow-hidden border h-[37px]">
                                <input
                                    type="text"
                                    value={fromValue}
                                    onChange={(e) => setFromValue(e.target.value)}
                                    placeholder="from"
                                    className="w-[100%] h-[100%] px-[10px] bg-white outline-none border-none"
                                />
                            </div>
                        <div className="w-[100px] border-gray-200/50 rounded-[10px] overflow-hidden border h-[37px]">
                                <input
                                    type="text"
                                    value={toValue}
                                    onChange={(e) => setToValue(e.target.value)}                                    placeholder="to"
                                    className="w-[100%] h-[100%] px-[10px] bg-white outline-none border-none"
                                />
                            </div>
                        <button
                            onClick={handleSubmit}
                            className="px-[20px] bg-main-300 text-white whitespace-nowrap rounded-[10px]">distance</button>
                    </div>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
