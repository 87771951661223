/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'
import { LabelingModal } from './LabelingModal'

export function LabelingTableItems({ item, index, modalId }) {
    const guruhname = item.tenantEntity ? item.tenantEntity.name : ''
    const fullname = `${item.firstName} ${item.lastName}`
    return (
        <>
            <TableCell>
                {index}
                {' '}
                .
            </TableCell>
            <TableCell>
                <div className="w-[100%] flex items-center justify-center">
                    {guruhname}
                </div>
            </TableCell>
            <TableCell>
                <div className="w-[100%] flex items-center justify-center">
                    {item.firstName}
                </div>
            </TableCell>
            <TableCell>
                <div className="w-[100%] flex items-center justify-center">
                    {item.lastName}
                </div>
            </TableCell>
            <TableCell>
                <div className="w-[100%] flex items-center justify-center">
                    <button className={`px-[20px] py-[10px] rounded-[10px] ${item.labelingStatus === 2
                        ? 'text-[#01C15A] bg-[#01c15a16]' : item.labelingStatus === 1
                            ? 'bg-[#fe625816] text-[#FE6258]'
                            : 'text-[#4393C7] bg-[#4393c716]'}`}
                    >
                        {item.labelingStatus === 1 ? 'Unchecked' : item.labelingStatus === 2 ? 'Checked' : 'Change'}
                    </button>
                </div>
            </TableCell>
            <TableCell>
                <div className="w-[100%] flex items-center justify-center">
                    <LabelingModal id={item.id} guruh={guruhname} fio={fullname} modalIds={modalId} />
                </div>
            </TableCell>
        </>
    )
}
