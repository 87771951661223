/* eslint-disable */
import { Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../shared/ui/SearchInput'
import Table from '../../../shared/ui/Table'
import { LabelingTableItems } from './LabelingTableItems'
import { useGetRequest } from '../../../shared/hooks/requests'
import { GET_LABELING } from '../../../shared/utils/urls'
import { LabelingFilter } from './LabelingFilter'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const cols = [
    {
        id: 1,
        title: '№',
    },
    {
        id: 2,
        title: 'Guruh nomi',
        align: 'center',
    },
    {
        id: 3,
        title: 'Firstname',
        align: 'center',
    },
    {
        id: 4,
        title: 'Lastname',
        align: 'center',
    },
    {
        id: 5,
        title: 'Status',
        align: 'center',
    },
    {
        id: 6,
        title: 'Harakat',
        align: 'center',
    },
]

export function LabelingTable() {
    const { labelFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const getLabiling = useGetRequest({ url: GET_LABELING })
    const data = getLabiling.response ? getLabiling.response.items : []
    const meta = getLabiling.response ? getLabiling.response.total : null
    const statics = getLabiling.response ? getLabiling.response : null
    const ids = data.map((item) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        name: item.tenantEntity?.name,
    }))
    const handleSearch = () => {

    }
    useEffect(() => {
        getLabiling.request({
            params: {
                page,
                size: pageSize,
                ...(labelFilter.region !== '' ? { region_id: labelFilter.region } : {}),
                ...(labelFilter.district !== '' && labelFilter.region !== ''
                    ? { district_id: labelFilter.district } : {}),
                ...(labelFilter.district !== '' && labelFilter.entity !== ''
                    ? { tenant_entity_id: labelFilter.entity } : {}),
            },
        })
    }, [page, pageSize, labelFilter])
    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <LabelingFilter />
            </div>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <div className="flex items-center gap-[15px]">
                        <Typography className="flex items-center gap-1_5 text-m font-600">
                            Labeling
                        </Typography>
                        <div className="flex items-center gap-[10px]">
                            <Typography className="flex items-center gap-1_5 text-m text-[#01C15A] font-600">
                                Checked
                            </Typography>
                            <Typography className="flex items-center gap-1_5 text-m text-[#01C15A] font-600">
                                {statics?.checked}
                            </Typography>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <Typography className="flex items-center gap-1_5 text-m text-[#FE6258] font-600">
                                Unchecked
                            </Typography>
                            <Typography className="flex items-center gap-1_5 text-m text-[#FE6258] font-600">
                                {statics?.unchecked}
                            </Typography>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <Typography className="flex items-center gap-1_5 text-m text-[#4393C7] font-600">
                                Changed
                            </Typography>
                            <Typography className="flex items-center gap-1_5 text-m text-[#4393C7] font-600">
                                {statics?.changed}
                            </Typography>
                        </div>
                    </div>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={getLabiling.loading}
                    totalCount={meta}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item, i) => (
                        <LabelingTableItems
                            index={i + 1}
                            item={item}
                            key={item.id}
                            modalId={ids}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
