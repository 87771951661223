/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'
import { SimilarityModal } from './SimilarityModal'

    export function SimilarityTableItems({ item }) {
    return (
        <>
            <TableCell>
                {item.identityId}
            </TableCell>
            <TableCell>
                {item.tenantEntityName}
            </TableCell>
            <TableCell>
                {item.firstName}
            </TableCell>
            <TableCell>
                {item.lastName}
            </TableCell>
            <TableCell>
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={item.imageUrl}
                        alt="Main"
                        className="w-[100%] h-[100%] object-cover"
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell>
                {item.pinfl}
            </TableCell>
            <TableCell className="w-[100%]">
                <SimilarityModal data={item.similarities} />
            </TableCell>
        </>
    )
}
