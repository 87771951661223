/* eslint-disable */
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Checkbox from '@mui/material/Checkbox'
import { COLORS } from '../../shared/utils/colors'
import { AuthLogo, AuthTitleLogo } from '../../components/svgs/SvgPictures'
import FormInput from '../../components/common/FormInput'
import { required } from '../../shared/utils/validators'
import Button from '../../components/common/Button'
import { usePostRequest } from '../../shared/hooks/requests'
import { AUTH } from '../../shared/utils/urls'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { EyeClose, EyeOpen } from '../../components/svgs/Svgs'
import { useMessage } from '../../shared/hooks/message'
import useHandleErrors from '../../shared/hooks/handleErrorMessage'

export const token = process.env.REACT_APP_UNIPASS_URL

export default function Authorization() {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [activeButton, setActiveButton] = useState('Login')
    const [isChecked, setIsChecked] = useState(false)
    const [passIcon, setPassIcon] = useState(false)
    const auth = usePostRequest({ url: AUTH })
    const location = useLocation()

    const src = `https://uni-pass.uz/signin/modul?profile_id=3&client_token=${token}`

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked)
    }

    const navigateToNewWindow = (url) => {
        // Open the URL in a new window/tab
        const newWindow = window.open('', '_blank')

        // Set the location of the new window to the desired URL
        newWindow.location.href = url
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const clientId = params.get('client_id')
        const accessToken = params.get('access_token')

        if (clientId && accessToken) {
            handleUniPassAuth(clientId, accessToken)
        }
    }, [location.search])

    const handleUniPassAuth = async (clientId, accessToken) => {
        try {
            const response = await axios.post('https://api.realsoft.ai/customer/uni-pass', null, {
                params: {
                    client_id: clientId,
                    token: accessToken,
                },
            })

            const { data } = response

            if (response.status === 200 && data.access_token) {
            // Store token and redirect on success
                localStorage.setItem('token', data.access_token)
                localStorage.setItem('userType', 'customer_user')
                window.location.href = '/analytics'
            } else {
                showMessage('Failed to authenticate with UniPass. Please try again.', 'error-msg')
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (!window.userNotFoundShown) {
                    showMessage('User not found. Please check your credentials.', 'error-msg')
                    window.userNotFoundShown = true
                }
            } else {
                showMessage('Error during UniPass authentication. Please try again.', 'error-msg')
            }
        }
    }

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType)
    }

    async function onSubmit(data) {
        if (auth.loading) {
            showMessage('Processing... Please wait', 'success-msg')
            return
        }
        const formData = new FormData()
        formData.append('username', data.username.trim())
        formData.append('password', data.password.trim())

        const { response, success, error } = await auth.request({ data: formData })

        if (success) {
            localStorage.setItem('token', response.accessToken)
            localStorage.setItem('userType', response.userType)

            const redirectMap = {
                system_admin: '/tenants',
                tenant_admin: '/company-structure',
                customer_user: '/analytics',
            }

            // noinspection UnnecessaryLocalVariableJS
            const redirectUrl = redirectMap[response.userType]
            window.location.href = redirectUrl
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const icons = () => (passIcon ? (
        <EyeOpen
            pointer
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setPassIcon(false)
            }}
        />
    ) : (
        <EyeClose
            pointer
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setPassIcon(true)
            }}
        />
    ))

    return (
        <div className={cn(css(s.wrap), 'w-full h-full flex-cent')}>
            <div className={cn(css(s.cont), 'flex-col item-center')}>
                <div className="w-full flex-col mb-[40px] items-center justify-center gap-4_5">
                    <AuthLogo />

                    <AuthTitleLogo />
                </div>

                <div className="relative w-[100%] mr-auto ml-auto px-[2px]
                py-[3px] h-auto flex items-center bg-[#76768014] rounded-[8px]"
                >
                    {/* Sliding white background */}
                    <span
                        className={`absolute top-0 left-0 h-full w-[50%] bg-white shadow-sm
                             shadow-gray-400 rounded-[8px] transition-transform duration-300 ease-in-out
            ${activeButton === 'UniPass' ? 'translate-x-full' : 'translate-x-0'}`}
                    />

                    {/* Buttons */}
                    <button
                        className={`relative z-10 w-[50%] cursor-pointer px-[10px] py-[3px] font-gilroy
                             font-normal rounded-[7px] transition-all duration-300 ease-in-out
            ${activeButton === 'Login' ? 'text-black' : 'text-gray-500'}`}
                        onClick={() => handleButtonClick('Login')}
                    >
                        Login
                    </button>
                    <button
                        className={`relative z-10 w-[50%] cursor-pointer px-[10px] py-[3px] font-gilroy
                             font-normal rounded-[7px] transition-all duration-300 ease-in-out
            ${activeButton === 'UniPass' ? 'text-black' : 'text-gray-500'}`}
                        onClick={() => handleButtonClick('UniPass')}
                    >
                        UniPass
                    </button>
                </div>

                {activeButton === 'Login' ? (
                    <Formik
                        initialValues={{ username: '', password: '' }}
                        // eslint-disable-next-line react/jsx-no-bind
                        onSubmit={onSubmit}
                    >
                        {({ errors, values }) => (
                            <Form className={cn(css(s.form), 'flex-col')}>
                                <>
                                    <FormInput
                                        name="username"
                                        value={values.username}
                                        validate={required}
                                        label="username (email)"
                                        errorWithMessage={false}
                                    />

                                    <FormInput
                                        type={passIcon ? 'text' : 'password'}
                                        name="password"
                                        value={values.password}
                                        validate={required}
                                        label="password"
                                        icon={icons()}
                                        errorWithMessage
                                    />

                                    <div className={cn(css(s.btnCont), 'flex-col gap-5 items-end')}>
                                        <div className="flex items-center justify-between w-[100%]">
                                            <div className="flex items-center gap-2">
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <h3
                                                    className="text-s font-600 text-blue-500
                                                 underline cursor-pointer"
                                                    onClick={() => navigateToNewWindow('/maktabgacha_talim_terms_and_conditions.html')}
                                                >
                                                    Terms of conditions
                                                </h3>
                                            </div>
                                            <h3 className="text-s font-600 text-[#AFAFAF]">Forgot Password?</h3>
                                        </div>

                                        <Button
                                            type="submit"
                                            className={cn(
                                                css(s.btn, isEmpty(errors)
                                            && Object.values(values).every((e) => e !== '' && isChecked === true)
                                                    && s.enabled),
                                                'w-full rounded-m text-m font-600 text-white ',
                                            )}
                                            disabled={!isChecked}
                                        >
                                            {auth.loading ? (
                                                <LoadingSpinner
                                                    width={20}
                                                    color={COLORS.white}
                                                    strokeWidth={1}
                                                />
                                            ) : 'Login'}
                                        </Button>
                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="w-[100%] h-[100%]" style={{ position: 'relative', overflow: 'auto' }}>
                        <iframe
                            style={{ height: '428px', position: 'relative', overflow: 'auto' }}
                            title="Unipass iframe"
                            src={src}
                            className="w-full"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: COLORS.sepia,
    },
    cont: {
        width: 450,
        padding: '20px 30px',
        backgroundColor: COLORS.white,
    },
    form: {
        marginTop: 40,
        gap: 32,
    },
    inp: {
        borderColor: 'transparent',
        borderRadius: '2px 2px 0px 0px',
        borderBottom: '1px solid #8D8E90',
        backgroundColor: COLORS.defaultInpBg,
        padding: '0 10px',
        color: COLORS.lightBlack,
        '::placeholder': {
            fontSize: 16,
            fontWeight: '400',
            color: COLORS.placeholder,
        },
        ':focus': {
            backgroundColor: COLORS.lightGray,
        },
    },
    btnCont: {
        marginTop: 50,
        ':nth-child(1n) > h3': {
            color: COLORS.gray,
        },
    },
    btn: {
        padding: '14px 0',
        backgroundColor: COLORS.disabled,
    },
    enabled: {
        backgroundColor: COLORS.mainBlueBg,
    },
})
